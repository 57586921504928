var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Embed types" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeTypes) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            "Supported embed types are iframe (default), video, embed and object, which translate to the standard HTML "
          )
        ]),
        _c("code", [_vm._v("<iframe>")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("<video>")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("<embed>")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("<object>")]),
        _c("span", [_vm._v(" elements.")])
      ]),
      _c(
        "div",
        [
          _c("b-embed", {
            attrs: {
              type: "iframe",
              aspect: "16by9",
              src: "https://www.youtube.com/embed/zpOULjyy-n8?rel=0",
              allowfullscreen: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }